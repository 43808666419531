@import '../variables';

.main-wrapper {
  .filter {
    @media (max-width: $breakpoint-960) {
      position: fixed;
      width: 35%;
      height: calc(100% - #{$header-height});
      min-width: 240px;
      z-index: 2;
      overflow: auto;
      top: $header-height;
      left: -100%;
      transition: left 0.2s ease;
    }
  }

  .mobile-filter-button-container {
    cursor: pointer;
    position: absolute;
    display: none;
    justify-content: space-around;
    align-items: center;
    left: 0.5rem;
    top: 0.5rem;
    width: 7.5rem;
    height: 1.875rem;
    border: 1px solid $form-border-active-color;
    border-radius: 5px;

    p {
      font-size: 0.75rem;
      font-weight: bold;
      color: $form-border-active-color;
    }

    img {
      width: 20px;
      height: 20px;
    }

    @media (max-width: $breakpoint-960) {
      display: flex;
      left: 2rem;
      top: 2rem;
    }

    @media (max-width: $breakpoint-640) {
      left: 0.5rem;
      top: 0.35rem;
    }

    @media (max-width: 375px) {
      left: 0.5rem;
      top: 0.35rem;
    }
  }
}

.filter {
  position: relative;
  top: 0;
  background-color: $filter-background-color;
  padding: 0 2vw;
  width: 300px;
  min-width: 150px;
  border-right: 1px solid $products-border-color;

  h3 {
    margin: 4vh 0;
    font-size: 1.125rem;
  }

  .close-button {
    position: absolute;
    cursor: pointer;
    display: none;
    top: 2vh;
    left: 88%;
    width: 1.5rem;
    height: 1.5rem;

    @media (max-width: $breakpoint-960) {
      display: block;
    }
  }
  
  .categories {
    width: 100%;
    margin: 6px 0 1.5rem;

    &.vacancy {
      margin: 6px 0 1rem;
    }

    h4 {
      font-weight: bold;
      font-size: 0.75rem;
    }

    p {
      font-size: 0.75rem;
    }
  }

  .input-horizontal-block {
    width: 100%;
    display:flex;
    align-items: center;
    box-sizing: border-box;

    .filter-item {
      max-width: 50%;

      &.left {
        border-radius: 5px 0 0 5px;
        border-right: none;
      }

      &.right {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  label {
    font-weight: bold;
    font-size: 0.75rem;
  }
}
