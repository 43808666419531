@import './variables.scss';
@import './company/base.scss';
@import './vacancy/base.scss';
@import './reset-confirm.scss';
@import './utils.scss';
@import './forms.scss';


html {
  font-size: 16px;

  @media (max-width: $breakpoint-1200) {
    font-size: 15px;
  }

  @media (max-width: $breakpoint-640) {
    font-size: 16px;
  }

  @media (max-width: 375px) {
    font-size: 14px;
  }

}

body {
  margin: 0;
  overflow-x: hidden;
  font-family:'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $main-background-color;

  @media (max-width: $breakpoint-960) {
    background-color: #fff;
  }

  .pop-up {
    opacity: 1;
  }
}

.main {
  position: relative;
  top: calc(#{$header-height} + #{$sub-header-height} + 2vh);
  min-height: calc(93vh - #{$header-height} - #{$sub-header-height} - #{$footer-height});

  @media (max-width: $breakpoint-960) {
    top: calc(#{$header-height} + 2vh);
  }
}

li {
  list-style-type: none;
  color: $black;
}

ul {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $black;
}

p, h1, h2, h3, h4 {
  color: $black;
  margin: 0;
}

button {
  font-family:'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  cursor: pointer;
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  color: $black;
  outline: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;

  &:focus {
    outline: 0;
  }
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

input {         // for FireFox
  min-width: 0;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 3.35px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 11.3px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #ffffff;
  border-radius: 4.8px;
  border: 1px solid #a7a7a7;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 2.6px 2.6px 2px rgba(255, 255, 255, 0), 0px 0px 2.6px rgba(255, 255, 255, 0);
  border: 1px solid #a7a7a7;
  height: 18px;
  width: 23px;
  border-radius: 18px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4.35px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ffffff;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 11.3px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #ffffff;
  border-radius: 4.8px;
  border: 1px solid #a7a7a7;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 2.6px 2.6px 2px rgba(255, 255, 255, 0), 0px 0px 2.6px rgba(255, 255, 255, 0);
  border: 1px solid #a7a7a7;
  height: 18px;
  width: 23px;
  border-radius: 18px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 11.3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #f2f2f2;
  border: 1px solid #a7a7a7;
  border-radius: 9.6px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #ffffff;
  border: 1px solid #a7a7a7;
  border-radius: 9.6px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 2.6px 2.6px 2px rgba(255, 255, 255, 0), 0px 0px 2.6px rgba(255, 255, 255, 0);
  border: 1px solid #a7a7a7;
  height: 18px;
  width: 23px;
  border-radius: 18px;
  background: #ffffff;
  cursor: pointer;
  height: 11.3px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #ffffff;
}
input[type=range]:focus::-ms-fill-upper {
  background: #ffffff;
}
