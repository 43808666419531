@import '../_variables.scss';
@import "../slider/image-gallery.scss";

.product-detail-wrapper {
  margin-bottom: 5vh;
  @media (max-width: $breakpoint-1200) {
    padding: 0 5vw 0 1vw;
  }

  .modal {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    padding-top: 25vh;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.5);
  }

  .pop-up {
    --width: 26.25rem;
    display: block;
    margin: auto;
    text-align: center;
    z-index: 10;
    width: var(--width);
    height: max-content;
    background-color: #ffffff;
    opacity: 1;
    box-shadow: 0px 0px 20px rgba($black, 0.25);

    .info-message {
      padding: 1.5rem 1rem 0.5rem 1rem;
      font-size: 1rem;
    }

    .switch-container {
      display: flex;
      width: 100%;
      margin: 3rem 0 2rem;

      .switch-button {
        width: 50%;
        padding: 0.7rem;
        text-align: center;
        font-weight: bold;
        font-size: 1.125rem;
        color: $short-description-color;
        border-bottom: 1px solid $products-border-color;

        &:hover {
          color: $black;
          border-bottom: 1px solid rgba($black, 0.70);;
        }
      }
    }

    .content-wrapper {
      padding: 0 5rem;
      .forms-container {
        margin-bottom: 1vh;

        .last-child {
          margin-bottom: 0;
        }
      }

      .buttons-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        justify-content: space-between;

        .action-button {
          margin-top: 0;
        }

        .reset-password-button {
          width:max-content;
          font-size: 0.75rem;
          font-weight: normal;
          color: $base-color-blue;
        }
      }
    }
  }


  .product-detail-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.2rem 2vw 1vh;
    background-color: #fff;
    border-bottom: 1px solid $products-border-color;

    h2 {
      font-weight: bold;
      font-size: 1.5rem;
      padding: 0;
      width: 70%;
    }

    p {
      font-size: 0.75rem;
      color: $dark-grey;
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: $main-background-color;

      a {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        margin: 2vh 0;

        img {
          width: 0.875rem;
          height: 0.875rem;
          margin-right: 0.5rem;
          transform: rotate(270deg);
        }
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      @media (max-width: $breakpoint-640) {
        flex-direction: column;

        h2 {
          width: 100%;
        }
      }

      .info {
        width: 30%;

        @media (max-width: $breakpoint-640) {
          width: 100%;
          flex-direction: row;
        }
      }
    }
  }

  .product-detail-main {
    display: flex;
    width: 100%;
    padding: 4vh 2vw 2vh;
    background-color: #fff;

    @media (max-width: $breakpoint-960) {
      flex-direction: column;
    }

    @media (max-width: $breakpoint-640) {
      padding: 2vh 2vw;
    }

    .right-column {
      font-size: 0.75rem;
      width: 25%;

      @media (max-width: $breakpoint-960) {
        display: flex;
        order: 1;
        width: 100%;
        flex-wrap: wrap;
      }

      @media (max-width: $breakpoint-640) {
        display: flex;
        order: 1;
        width: 100%;
        flex-direction: column;
      }

      .price-info {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $products-border-color;

        @media (max-width: $breakpoint-960) {
          width: 30%;
          padding-right: 20%;
        }

        @media (max-width: $breakpoint-640) {
          width: 100%;
          padding-right: 0;
        }

        p {
          margin: 0.5rem 0;
        }

        .price {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.125rem;

          &:hover {
            cursor: help;
            .price__pop-up {
              display: flex;
            }
          }

          p {
            margin: 0;
          }

          .preferred {
            display: block;
            color: #fff;
            padding: 2px 0.5rem;
            font-weight: bold;
            font-size: 1.5rem;
            background-color: $base-color-blue;
          }

          .price__pop-up {
            position: absolute;
            display: none;
            flex-direction: column;
            width: 8rem;
            min-width: max-content;
            max-width: 11rem;
            top: 120%;
            left: 40%;
            padding: 0.5rem 1rem;
            background-color: #fff;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

            p {
              margin: 0.1rem 0;
            }
          }
        }

        .trade-block {
          display: flex;
          align-items: center;

          img {
            width: 0.875rem;
            height: 0.875rem;
            margin-right: 0.5rem;
          }
        }
      }

      .state-info {
        width: 100%;
        padding: 0.75rem 0;
        border-bottom: 1px solid $products-border-color;

        @media (max-width: $breakpoint-960) {
          width: 50%;
          padding: 0;
        }

        @media (max-width: $breakpoint-640) {
          width: 100%;
          align-content: center;
        }

        .state-item {
          display: flex;
          font-size: 0.9rem;
          margin: 1rem 0;

          .left-item {
            min-width: 60%;

            &.img {
              height: 1.2rem;

              img {
                width: 5.8rem;
                height: 1.2rem;
              }
            }
          }

          .right-item {
            font-weight: bold;
          }
        }

        .location {
          display: flex;
          align-items: center;
          margin: 1.25rem 0;
          font-size: 0.9rem;

          img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
          }
        }
      }

      .contacts {
        @media (max-width: $breakpoint-960) {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          padding: 1rem 0 1.5rem;
          margin: 1rem 0;
          border-bottom: 1px solid $products-border-color;
        }

        @media (max-width: $breakpoint-640) {
          flex-direction: column;
        }

        .owner {
          display: flex;
          align-items: center;
          margin: 2rem 0 1rem;
          font-weight: bold;

          &.company {
            flex-direction: column;
            margin: 1rem 0;

            &:hover .title {
              color: $base-color-blue;
            }
          }

          @media (max-width: $breakpoint-960) {
            margin: 0.75rem 0;
          }

          @media (max-width: $breakpoint-640) {
            margin: 0 0 0.75rem;
            align-self: flex-start;
            font-size: 1rem;
          }

          .avatar {
            width: 50px;
            height: 50px;
            margin-right: 1rem;
            border: 1px solid $base-color-blue;
            border-radius: 50%;

            &.company {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0;
              border: none;
              margin: 0 0 0.5rem 0;
              width: 170px;
              height: 100px;
              max-width: 170px;
              min-width: 170px;
              max-height: 100px;
              min-height: 100px;

              img {
                height: inherit;
                width: auto;
                max-height: inherit;
                max-width: inherit;
                border-radius: 0;
              }
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .title {
            font-size: 1.5rem;
          }
        }

        .action-button {
          &.delete {
            background-color: #fff;
            color: $short-description-color;

            &:hover {
              color: $invalid-form-border-color;
            }
          }

          &.link {
            padding: 0.6rem 0;
          }
        }

        .tel-link {
          margin: 0.5rem 0;
          padding: 0.5rem 0;
          width: 100%;
          max-height: 2.8rem;
          text-align: center;
          background-color: $form-disabled-color;
          border: 1px solid $form-disabled-color;
          border-radius: 5px;

          @media (max-width: $breakpoint-960) {
            max-width: 13rem;
          }

          @media (max-width: $breakpoint-640) {
            max-width: 100%;
          }

          a {
            font-size: 1.2rem;
          }
        }

        button, .link {
          margin: 0.75rem 0;
          max-width: 100%;
          max-height: 2.8rem;

          @media (max-width: $breakpoint-960) {
            max-width: 13rem;
          }

          @media (max-width: $breakpoint-640) {
            max-width: 100%;
          }
        }
      }
    }

    .left-column {
      width: 70%;
      padding-right: 5%;

      @media (max-width: $breakpoint-960) {
        order: 2;
        width: 100%;
        padding-right: 0;
      }

      h3 {
        margin: 3rem 0 1rem;
        font-size: 1.3rem;
        font-weight: normal;
        color: $black;
      }

      .type {
        min-width: 8.5rem;
        color: $short-description-color;
        font-size: 0.9rem;

        @media (max-width: $breakpoint-640) {
          min-width: 11rem;
        }
      }

      .value {
        color: $black;
        font-size: 0.9rem;
      }

      .blocks {
        display: flex;
        justify-content: space-between;

        @media (max-width: $breakpoint-640) {
          flex-direction: column;
        }

        .block {
          width: 50%;

          @media (max-width: $breakpoint-640) {
            width: 100%;
          }

          .block-item {
            display: flex;
            width: 100%;
            margin: 1rem 0;
          }
        }
      }

      .state {
        width: 100%;
      }

      .description {
        width: 100%;

        p {
          margin: 0.7rem 0;
          font-weight: normal;
          font-size: 1rem;
          color: $black;

          span{
            padding: 3px 10px 3px 3px;
            border-bottom: 1px solid $short-description-color;
          }
        }


        .description-exchange {
          font-weight: normal;
          font-size: 1rem;
          color: $black;
        }
      }

      .condition {
        width: 100%;
        display: flex;

        .block-wrapper {
          width: 100%;

          .block {
            width: 100%;
          }
        }
      }
    }
  }
}
