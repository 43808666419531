@import "_variables.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic");

.footer {
  background-color: #212529;
  position: relative;
  top: calc(#{$header-height} + #{$sub-header-height} + 2vh);
  min-height: $footer-height;
  margin-top: 5vh;
  padding: 0 10vw;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  color: $base-color-orange;

  @media (max-width: $breakpoint-1200) {
    padding: 0 2vw;
  }

  @media (max-width: $breakpoint-960) {
    flex-wrap: wrap;
  }

  @media (max-width: $breakpoint-640) {
    font-size: 12px;
  }

  .copyright {
    display: flex;
    flex-direction: column;
    margin-top: $footer-margin-top;

    width: 25%;
    padding-left: 2vw;

    img {
      max-width: 91px;
      height: auto;
      margin-bottom: 14px;
    }

    p {
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
    }

    @media (max-width: $breakpoint-960) {
      order: 2;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 25px;

      img {
        margin-bottom: 0px;
        margin-right: 20px;
        max-width: 35px;
      }
    }

    
    @media (max-width: $breakpoint-640) {
      margin-top: 40px;
      margin-bottom: 20px;

      img {
        max-width: 21px;
      }
    }
  }

  .sub-footer {
    margin-top: $footer-margin-top;
    width: 25%;

    @media (max-width: $breakpoint-960) {
      width: 25%;
      margin-left: 20px;
    }

    @media (max-width: $breakpoint-640) {
      width: 100%;
      margin-left: 0px;
    }

    .nav {
      display: flex;
      flex-direction: column;

      a {
        color: #fff;
      }

      a:not(:first-of-type) {
        margin-top: 20px;
      }

      @media (max-width: $breakpoint-640) {
        flex-direction: row;
        font-size: 12px;

        a {
          margin-right: 15px;
          margin-left: 10px;
          margin-top: 0px !important;
        }
      }
    }
  }

  .contacts {
    width: 25%;
    margin-top: $footer-margin-top;
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;

    .address {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      max-width: 250px;

      .image-address {
        margin-right: 10px;
      }

      p {
        font-weight: normal;
        font-size: 12px;
        color: #ffffff;
      }
    }

    .phone-contacts {
      display: flex;
      margin-top: 20px;
      max-width: 250px;

      li {
        font-weight: normal;
        margin-left: 10px;
        font-size: 12px;
      }

      a {
        color: #ffffff;
      }
    }

    @media (max-width: $breakpoint-960) {
      width: 40%;
    }
    @media (max-width: $breakpoint-640) {
      width: 45%;
      margin-top: 30px;
      margin-left: 10px;

      a,
      p {
        font-size: 10px !important;
      }

      img {
        max-width: 18px;
      }
    }
  }

  .support {
    margin-top: $footer-margin-top;
    display: flex;
    flex-direction: column;

    a {
      color: #ffffff;
      font-weight: normal;
      font-size: 12px;
      margin-left: 10px;
    }

    .suppor-mail {
      margin-top: 25px;
      display: flex;
      align-items: center;
    }

    .suppor-phone {
      margin-top: 25px;
      display: flex;
      align-items: center;
    }

    @media (max-width: $breakpoint-640) {
      width: 45%;
      margin-top: 30px;
      margin-left: 15px;

      a {
        font-size: 10px !important;
      }

      img {
        max-width: 18px;
      }
    }
  }
}
