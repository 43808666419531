@import "_variables.scss";

.wrong-password-popup {
  position: absolute;
  width: 400px;
  height: 319px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border-radius: 5px;

  img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 370px;
    top: 10px;

    cursor: pointer;
  }

  .title {
    position: absolute;
    width: 340px;
    height: 89px;
    left: 30px;
    top: 30px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: #212529;
  }
  .phone-title {
    position: absolute;
    width: 170px;
    height: 21px;
    left: 30px;
    top: 129px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #212529;
  }

  .primary-btn {
    position: absolute;
    width: 340px;
    height: 40px;
    left: 30px;
    top: 190px;

    background: #036d9e;
    border-radius: 5px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: white;
    text-align: center;
  }

  .secondary-btn {
    position: absolute;
    width: 228px;
    height: 21px;
    left: 86px;
    top: 250px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #036d9e;
  }
}

.verified-status {
  height: 25px;
  width: 25px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  cursor: pointer;
}

.invalid-verified-status {
  background-color: $base-color-orange;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
  cursor: pointer;
  height: 21px;
  width: 21px;
  padding: 2px;
}

.new-user-alert {
  position: absolute;
  width: 400px;
  height: 426px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border-radius: 5px;

  img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 370px;
    top: 10px;

    cursor: pointer;
  }

  .title {
    position: absolute;
    width: 340px;
    height: 294px;
    left: 30px;
    top: 30px;

    font-family: Roboto;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;

    color: #212529;
  }

  .secondary-btn {
    position: absolute;
    width: 56px;
    height: 21px;
    left: 30px;
    top: 364px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #036d9e;
  }

  .primary-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 20px;

    position: absolute;
    width: 226px;
    height: 40px;
    left: 144px;
    top: 354px;

    text-align: center;

    background: #036d9e;
    border-radius: 5px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    text-align: right;

    color: #ffffff;
  }
}
