@import "../_variables.scss";

.vacancy-detail-wrapper {
  display: flex;
  margin-bottom: 5vh;
  background-color: #fff;

  @media (max-width: $breakpoint-1200) {
    padding: 0 5vw 0 1vw;
  }

  .mail-link {
    color: $base-color-blue;
  }

  .vacancy-detail-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4vh 2vw 2vh;
    background-color: #fff;

    @media (max-width: $breakpoint-960) {
      padding: 4vh 0 2vh;
    }
  }

  .vacancy-detail-control {
    display: flex;
    margin: 0 1vw;
    border-bottom: 1px solid #e5e5e5;

    .action-button {
      background-color: white;
      margin: 20px 0;
      width: 20%;
      min-width: 180px;
      text-align: left;
      font-weight: normal;

      @media (max-width: $breakpoint-320) {
        min-width: 50%;
      }

      &.delete {
        color: #db4040;
      }

      &.link {
        color: #036d9e;
      }
    }
  }

  .vacancy-left-column {
    width: 18.75rem;
    max-width: 18.75rem;
    padding: 2rem 1.5vw;
    border-right: 1px solid $products-border-color;

    @media (max-width: $breakpoint-960) {
      display: none;
    }

    h3 {
      margin-top: 1rem;
    }

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15rem;
      max-height: 15rem;
      margin-bottom: 1.5rem;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .contact-blocks {
      padding: 1.25rem 0;

      .block {
        padding: 0.75rem 0;

        h3 {
          margin: 0 0 0.5rem 0;
        }
      }
    }

    .credentials {
      padding-top: 1.5rem;

      p {
        color: $short-description-color;
      }
    }
  }

  .vacancy-detail-header {
    padding: 0 0.5vw 0.5rem;
    margin: 0 1vw;
    border-bottom: 1px solid $products-border-color;

    h2 {
      padding: 0.25rem 0;
    }

    .salary {
      color: $base-color-blue;
    }

    .credentials {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.75rem;

      @media (max-width: $breakpoint-640) {
        flex-direction: column;
        align-items: flex-start;
      }

      .link-block {
        display: flex;

        a {
          display: none;
          color: $base-color-blue;

          @media (max-width: $breakpoint-960) {
            display: inline;
          }

          &::before {
            content: "|";
            margin: 0 6px;
            color: #000000;
          }
        }
      }

      .state {
        display: flex;
        align-items: center;

        p {
          color: $short-description-color;

          &:first-child::after {
            content: "|";
            padding: 0 0.5rem;
          }
        }
      }
    }
  }

  .vacancy-detail-main {
    padding: 0.5rem 0.5vw;
    margin: 0 1vw;

    h2 {
      padding: 0 0 2rem;
      font-weight: normal;
    }

    .description-section {
      padding: 1rem 0 2rem;
      border-bottom: 1px solid $products-border-color;

      .state-section {
        display: flex;
        padding: 1rem 0 2rem;

        @media (max-width: $breakpoint-960) {
          flex-direction: column;
        }

        .blocks {
          width: 50%;

          @media (max-width: $breakpoint-960) {
            width: 100%;
          }

          .block {
            display: flex;
            align-items: center;
            padding: 0.5rem 0;

            img {
              padding-right: 0.2rem;
            }
          }
        }
      }
    }

    .contacts-section {
      padding: 1.5rem 0;

      .contact-block {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;

        .name {
          color: $short-description-color;
          min-width: 15rem;

          @media (max-width: $breakpoint-640) {
            display: none;
          }
        }
      }
    }
  }
}
