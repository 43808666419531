$base-color-orange: #faab02;
$base-color-blue: #036d9e;
$base-color-red: #DB4040;
$black: #212529; 
$dark-grey: #737373;
$main-background-color: #f5f5f5;
$products-border-color: #e5e5e5;
$form-background-active-color: #cbe4f0;
$form-border-color: #d2d7d9;
$form-border-active-color: #0093D7;
$form-disabled-color: #EBEBE4;
$short-description-color: #A7A7A7;
$filter-background-color: #F7FDFF;
$invalid-form-border-color: #DB4040;
$invalid-form-background-color: #FFE1E1;

$breakpoint-1200: 1200px;
$breakpoint-960: 960px;
$breakpoint-640: 640px;
$breakpoint-320: 320px;

$header-height: 80px;
$sub-header-height: 40px;

$footer-height: 200px;
$footer-margin-top: 4vh;

$theme-colors: (
    "primary": $base-color-blue,
    "warning": $base-color-orange,
    "danger": $base-color-red
);

