@import '../variables.scss';

.profile-container {
  padding: 3vh 5vw; 
  background-color: #fff;

@each $color, $value in $theme-colors {
  .confirm-banner-#{$color} {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5vh;
    padding: 6px;
    border: 1px solid $value;
    background-color: rgba($value, 0.1);

    .img-container {
      width: 80px;
      height: 80px;
      padding-right: 1rem;
      max-width: 80px;
      min-width: 80px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .request-confirm-button {
      color: $base-color-blue;
      font-weight: bold;
    }
  }
}

  .user-photo-section {
    width: 100%;
    padding-bottom: 6vh;
    border-bottom: 1px solid $products-border-color;

    p {
      font-size: 0.75rem;
      color: $short-description-color;
    }

    .photo-section__header {
      width: 50%;
      min-width: 400px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 2vh 0 6vh;

      @media (max-width: 450px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        min-width: 0;

        p {
          margin-top: 0.1rem;
        }
      }
    }

    .photo-section__main {
      display: flex;
      align-items: center;
      width: 50%;
      min-width: 400px;

      @media (max-width: 450px) {
        min-width: 0;
        width: 100%;
      }

      .avatar-container {
        margin-right: 2rem;
        width: 100px;
        height: 100px;
        min-width: 100px;
        border-radius: 50%;
        border: 1px solid $base-color-blue;

        @media (max-width: 450px) {
          width: 90px;
          height: 90px;
          min-width: 90px;
        }
        
        img {
          width: 100%;
          height: 100%;
          border-radius: inherit;
        }
      }
    }
  }

  .registration-data-container {
    padding: 5vh 0;
    border-bottom: 1px solid $products-border-color;

    h3 {
      margin-bottom: 2rem;

      @media (max-width: 450px) {
        margin-bottom: 1.5rem;
      }
    }

    .data-block {
      display: flex;
      align-items: baseline;
      width: 100%;

      @media (max-width: 450px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;
      }

      a {
        color: $base-color-blue;
      }

      label {
        width: 20%;
        min-width: 200px;
        margin: 0.75rem 0;
        color: $short-description-color;

        @media (max-width: 450px) {
          margin: 0.2rem 0;
        }
      }
    }
  }

  .person-data-container {
    padding-top: 5vh;

    h3 {
      margin-bottom: 2rem;
    }

    .payload-container {
      display: flex;
      align-items: baseline;
      width: 100%;

      @media (max-width: 450px) {
        flex-direction: column;
        align-items: flex-start;

        label {
          margin-left: 0.2rem;
        }
      }

      .payload-container {
        @media (max-width: 450px) {
          flex-direction: row;
        }
      }

      input[type='country'] {
        margin-right: 1rem;
      }

      label {
        width: 20%;
        min-width: 200px;
        color: $short-description-color;
      }
    }

    .button-container {
      width: 30%;
      min-width: 12.5rem;
      margin: 0 auto;
    }
  }
}


