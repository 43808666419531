.company-dashboard-wrapper {
  padding: 4vh 4vw;
}

.company-dashboard-wrapper__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4vh;

  @media(max-width: $breakpoint-640) {
    flex-direction: column-reverse;
  }

  .text-container {
    margin-right: 1rem;

    @media(max-width: $breakpoint-640) {
      margin: 0;
    }

    .company-title {
      padding: 0;
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;

      @media(max-width: $breakpoint-640) {
        margin: 1rem 0;
      }
    }
  }

  .logo-container {
    min-width: 230px;
    max-width: 231px;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.company-dashboard-wrapper__nav {
  display: flex;
  border-bottom: 1px solid $products-border-color;
  margin-bottom: 1vh;

  &.ads {
    padding-top: 1vh;
    margin-bottom: 0;
    background-color: #fff;
  }

  li {
    padding: 0.5rem 1rem;

    &.active {
      border-bottom: 2px solid $base-color-blue;

      a {
        color: $base-color-blue;
      }
    }

    a {
      font-size: 1.125rem;
      font-weight: bold;
      padding: 0.5rem 1rem;
      color: $short-description-color;

      @media(max-width: $breakpoint-640) {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
      }
    }
  }
}

.company-dashboard-wrapper__company-information,
.company-dashboard-wrapper__company-employees {

  h3 {
    padding: 1.5rem 0;
  }

  .edit {
    margin: 1.5rem auto 1rem !important;
    max-width: 10rem;
  }

  .leave {
    text-align: center;
    font-size: 0.8em;
  }

  a {
    color: $base-color-blue;
  }

  .information {
    display: flex;

    @media(max-width: $breakpoint-640) {
      flex-direction: column;
    }

    p {
      display: inline-block;
    }

    p, a {
      padding: 0.75rem 0;

      .work-day-name {
        display: inline-block;
        min-width: 150px;
      }

      .holiday {
        color: #DB4040;
      }
    }

    &.column {
      flex-direction: column;
      max-width: 40%;
      margin-right: 5%;

      @media(max-width: $breakpoint-640) {
        max-width: 100%;
        margin-right: 0;
      }
    }
  }
}

.company-dashboard-wrapper__company-employees {
  .staff-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: 42%;
      padding: 1rem 1.5rem;
      margin-bottom: 2rem;
      font-size: 0.85rem;
      border: 1px solid $products-border-color;
      border-radius: 7px;

      .item-card, .pending-actions-container {
        display: flex;
      }

      .actions-container {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        .decline, .accept {
          display: inline-block;
          cursor: pointer;
          text-decoration: underline;
        }

        .decline {
          color: $invalid-form-border-color; 
          text-decoration-color: $invalid-form-border-color;
        }

        .accept {
          color: $base-color-blue;
          text-decoration-color: $base-color-blue;
        }
      }

      .text-container {
        .admin {
          content: 'Администратор';
          margin-left: 0.5rem;
          padding: 3px 10px;
          font-size: 0.75rem;
          font-weight: normal;
          color: #ffffff;
          background-color: $base-color-blue;
          border-radius: 15px;
          float: right;
        }

        p {
          max-width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 1rem;
        }

        a {
          color: $short-description-color;
        }
      }
      img {
        min-width: 50px;
        height: 50px;
        vertical-align: middle;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
  }
}
