@import "./variables.scss";

//-----------------loading-animation-------------------
.loading-spinner {
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: 50%;
  border: 5px solid $base-color-orange;
  border-top-color: $base-color-blue;
  animation: spinner2 600ms linear infinite;
}

.loading-text{
  margin-top: 30px;
  letter-spacing: 1px;
  text-align: center;
}

@keyframes spinner2 {
  to {
    transform: rotate(360deg);
  }
}
//------------ReactModal--------------

.ReactModal__Overlay--notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5vh;
}

.button {
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  padding: 0 15px;
  cursor: pointer;

  font-weight: bold;
  font-size: 18px;
}

.modal-confirm {
  max-width: 340px;
  max-height: 340px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -115px;

  background-color: white;
  border-radius: 5px;
  outline: none;
  padding: 30px;
  font-size: 18px;

  display: inline-block;

  p {
    margin-bottom: 30px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @each $color, $value in $theme-colors {
      .button-confirm-#{$color} {
        p {
          color: white;
        }
        background-color: $value;
      }
    }

    .button-cancel {
      p {
        font-weight: normal;
        color: $short-description-color;
      }

      border: 1px solid $short-description-color;
      box-sizing: border-box;
    }
  }
}

.modal-confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
}

.ReactModal__Content--notification {
  position: absolute;
  width: 15rem;
  padding: 1.4rem;
  left: calc(50vw - 15rem / 2);
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  transition: bottom 0.1s ease-in;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border: 2px solid $base-color-blue;
  border-radius: 5px;

  .content {
    display: flex;
    align-items: center;
    font-weight: bold;

    p {
      margin-right: 0.5rem;
    }

    img {
      width: 2.5rem;
      height: 2.5rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
      filter: hue-rotate(50deg);
    }
  }
}

.ReactModal__Overlay--notification--after-open {
  .ReactModal__Content--notification {
    bottom: 5vh;
  }
}

.ReactModal__Overlay--loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactModal__Content--loading {
  position: absolute;
  left: calc(50vw - 100px / 2);
  top: calc(50vh - 100px / 2);
  outline: none;
  -webkit-overflow-scrolling: touch;
}

//---------------404page--------------
.not-found {
  margin: 0 10vw;
  height: 80vh;
  text-align: center;
  background: $base-color-blue 35% 100% url("../img/404-background.png")
    repeat-x;

  @media (max-width: 470px) {
    margin: 0;
    padding: 5vh 0 0;
    height: 100vh;
  }

  p {
    padding: 0.2rem;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;

    @media (max-width: 470px) {
      font-size: 1.125rem;
    }
  }

  .text-404 {
    font-size: 12.5rem;

    @media (max-width: 470px) {
      font-size: 7.5rem;
    }
  }
}
//------------------------------------

.action-button {
  cursor: pointer;
  width: 100%;
  margin: 3vh 0;
  padding: 1vh 0;
  outline: none;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  background-color: $base-color-orange;
  border-radius: 5px;

  &.secondary {
    background-color: $products-border-color;
  }

  &.link {
    display: block;
    margin: 0;
  }

  &.text-404-link {
    display: block;
    margin: 20% auto 0;
    background-color: #fff;
    width: 18rem;
  }

  &.profile {
    background-color: $base-color-blue;
    color: #fff;
    border: 1.5px solid $base-color-blue;

    &.light {
      background-color: #fff;
      color: $base-color-blue;

      p {
        font-size: 1rem;
        color: $base-color-blue;
      }
    }
  }
}

.filter-item,
.auth-input,
.product-input,
.person-input {
  width: 100%;
  height: 2.5rem;
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  margin: 6px 0 1.5rem;
  outline: 0;
  display: inline-block;
  border: 1px solid $form-border-color;
  border-radius: 5px;
  box-sizing: border-box;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 1rem;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 1rem;
  }

  &.disabled {
    background-color: $form-disabled-color;
  }

  &:not(.disabled):hover {
    background-color: $form-background-active-color;
  }

  &:focus:hover,
  &:focus {
    background-color: #ffffff;
    border: 1px solid $form-border-active-color;
  }

  &.invalid {
    border: 1px solid $invalid-form-border-color;
    background-color: $invalid-form-background-color;

    &:focus:hover,
    &:focus {
      border: 1px solid $invalid-form-border-color;
      background-color: $invalid-form-background-color;
    }
  }
}

//--------checkbox and radio-----------

input[type="checkbox"] {
  margin-left: 0;
}
.vendorCheckbox{
  margin-left: 7px;

  @media (max-width: $breakpoint-960){
    margin-left: 0;
  }
}
.checkbox-container {
  width: 100%;
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
    font-weight: normal;
    margin-left: 0.5rem;
  }

  .checkbox {
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid $form-border-color;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 0.5rem;
    border-radius: 3px;
    display: inline-block;
    position: relative;

    &:active,
    &:checked:active {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    &:hover {
      background-color: $form-background-active-color;
    }

    &:checked {
      background-color: #fff;
      border: 1px solid $form-border-active-color;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
      color: $form-border-active-color;
    }

    &:checked:after {
      content: "\2714";
      font-size: 0.75rem;
      position: absolute;
      top: 0px;
      left: 3px;
      color: $form-border-active-color;
    }
  }
}

$radioSize: 22px;
$radioBorder: #d1d7e3;
$radioActive: #5d9bfb;

.radio {
  margin: 0.5rem 0;
  display: block;
  cursor: pointer;
  input {
    display: none;
    & + span {
      line-height: $radioSize;
      height: $radioSize;
      padding-left: $radioSize;
      display: block;
      position: relative;
      &:not(:empty) {
        padding-left: $radioSize + 8;
      }
      &:before,
      &:after {
        content: "";
        width: $radioSize;
        height: $radioSize;
        display: block;
        border-radius: 50%;
        left: 0;
        top: 0;
        position: absolute;
      }
      &:before {
        background: $radioBorder;
        transition: background 0.2s ease,
          transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
      }
      &:after {
        background: #fff;
        transform: scale(0.78);
        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
      }
    }
    &:checked + span {
      &:before {
        transform: scale(1.04);
        background: $radioActive;
      }
      &:after {
        transform: scale(0.4);
        transition: transform 0.3s ease;
      }
    }
  }
}

//----------forms-------------

.work-day-button {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  color: #db4040;
  text-align: center;
  border: 1px solid #db4040;
  border-radius: 5px;
  background-color: #fff;

  &.active {
    color: #fff;
    border-color: $base-color-blue;
    background-color: $base-color-blue;
  }
}

.form-invalid-indicator {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: #ff0000;

  &::before {
    margin: 0 1vw;
    content: url("../icons/form-invalid-icon.png");
  }
}

.page-title {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1.5rem 0 1rem 3rem;
  color: $black;
  border-bottom: 1px solid $form-border-color;
}

//--------------------info bars--------------------

.loaded,
.updated,
.emptyFields,
.error,
.deleting,
.deleted,
.no-result,
.canceling {
  margin: 15vh auto;
  text-align: center;
  font-size: 1.3rem;
  width: 50vw;
  padding: 5rem;
  border: 1px solid $base-color-orange;
  background-color: #ffffff;

  @media (max-width: $breakpoint-960) {
    margin: 10vh auto;
    padding: 4rem;
  }

  a {
    color: $base-color-blue;

    &:hover {
      color: $base-color-orange;
    }
  }

  p {
    margin: 0.2rem;
  }
}

.deleted {
  margin-left: 4vh;
  display: inline-block;
  vertical-align: middle;
}

.no-result {
  width: calc(100% - 10rem);
}

.loading {
  background-color: unset;
}

.loaded {
  width: max-content;
  padding: 0rem;
  border: none;
  background: none;

  &.product {
    width: 100%;
  }

  .loaded-message {
    margin-bottom: 4vh;
  }

  @media (max-width: $breakpoint-640) {
    width: unset;
    height: 20vh;
    margin: 10vh auto;
    padding: 2rem;
  }

  .product-list {
    text-align: left;
    background: none;
    padding: 5vh 0;

    @media (max-width: $breakpoint-640) {
      display: none;
    }

    .product {
      padding: 4vh 2vw;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
  }
}

.deleting {
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .action-button {
      max-width: 20rem;
      margin: 3vh 0 2vh;

      &:hover {
        background-color: lightgreen;
      }

      &.secondary:hover {
        color: $invalid-form-border-color;
        background-color: $invalid-form-background-color;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.canceling {
  border-width: 0;
  border-radius: 5px;
  margin: 5vh auto;
  text-align: left;
  padding: 2rem;
  max-width: 400px;
  min-width: 290px;
  font-size: 1.1rem;

  .button-container {
    display: flex;
    align-items: center;

    .action-button {
      max-width: 20rem;
      color: $invalid-form-border-color;
      font-weight: normal;

      &.cancel {
        background-color: #ffffff;
        border: 1px solid $short-description-color;
        margin-right: 20px;
        max-width: 20rem;
        color: #a7a7a7;
      }

      &.secondary {
        color: white;
        font-weight: bold;
        background-color: $invalid-form-border-color;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.preview-modal {
  height: 90%;
  width: 70%;
  top: 5%;
  left: 15%;
  position: absolute;
  iframe {
    height: 100%;
    width: 100%;
  }
}

.preview-btn {
  padding: 10px;
  margin-bottom: 10px !important;
  width: auto;
  background-color: $base-color-blue;
  color: white;
}

.preview-btn-mr {
  margin: 10px;
}
