@import "_variables.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic");

.header {
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 0 10vw;
  z-index: 10;
  height: $header-height;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(33, 37, 41, 0.25);

  @media (max-width: $breakpoint-1200) {
    padding: 0 2vw;
    width: 96%;
  }

  .sub-header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    height: $sub-header-height;
    width: 80%;
    padding: 0 10vw;
    top: $header-height;
    left: 0;
    background-color: $base-color-orange;
    box-shadow: 0px 4px 10px rgba(33, 37, 41, 0.25),
      inset 3px 1px 5px rgba(33, 37, 41, 0.25);

    @media (max-width: $breakpoint-1200) {
      padding: 0 2vw;
      width: 96%;
    }

    @media (max-width: $breakpoint-960) {
      display: none;
      visibility: hidden;
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 30%;

      .nav-item {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1rem;
        font-size: 1.125rem;
        font-weight: bold;

        &:hover,
        &.active {
          background-color: rgba(#ffffff, 0.4);
        }
      }
    }
  }

  .trigger .action-button--sub-header {
    margin: 0;
    width: 50%;
    font-size: 1.125rem;
  }

  .action-button--sub-header {
    width: 20%;
    min-width: 12rem;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.725rem;
    color: #ffffff;
    border-radius: 0;
    background-color: $base-color-blue;

    &:hover {
      background-color: #ffffff;
      color: $base-color-blue;
    }
  }

  .mobile-menu-button-container {
    width: 40%;
    display: none;

    @media (max-width: $breakpoint-960) {
      display: block;
    }

    .mobile-menu-button {
      cursor: pointer;
    }
  }

  .nav-container {
    width: 40%;

    @media (max-width: $breakpoint-960) {
      display: none;
    }

    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav-item {
        margin: 0 0.1vw;
        a {
          font-size: 1.125rem;
          color: $base-color-blue;
          font-weight: bold;
          display: flex;
          text-decoration: none;

          img {
            margin-left: 5px;
          }

          @media (max-width: 1420px) {
            font-size: 0.94rem;
          }

          &:hover {
            color: $base-color-orange;
          }
        }
      }
    }
  }

  .mobile-nav-container {
    position: fixed;
    flex-direction: column;
    display: none;
    width: 210px;
    min-width: 210px;
    height: 100vh;
    left: -100%;
    top: $header-height;
    z-index: 2;
    background-color: #fff;
    transition: left 0.2s ease;
    box-shadow: 0px 10px 10px rgba(33, 37, 41, 0.25);
    overflow-y: auto;

    @media (max-width: $breakpoint-960) {
      display: flex;
    }

    &.open {
      left: 0;
    }

    .auth-container.trigger {
      width: 100%;
    }

    .action-button.link {
      margin: 2rem 10px;
      width: calc(100% - 20px);
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1.725rem;
      color: #ffffff;
      background-color: $base-color-blue;

      &.profile.light {
        background-color: #fff;
        color: $base-color-blue;
      }

      &:not(.disable-link):hover {
        background-color: #ffffff;
        color: $base-color-blue;
      }
    }

    .nav {
      display: flex;
      flex-direction: column;
      padding: 0 10px;

      &.dashboard {
        padding: 10px 0;
        align-items: flex-start;
        background-color: $base-color-orange;
        box-shadow: inset 3px 1px 5px rgba(33, 37, 41, 0.25);

        .nav-item {
          display: flex;
          align-items: center;
          padding: 0 10px;
          margin: 0.3rem 0;
          height: 2.5rem;
          width: calc(100% - 20px);
          font-size: 1.125rem;
          font-weight: bold;

          img {
            margin-left: 5px;
          }

          &:hover,
          &.active {
            background-color: rgba(#ffffff, 0.4);
          }
        }
      }

      .nav-item {
        margin: 1vh 0.2vw;
        display: flex;
        align-items: center;

        img {
          margin-left: 5px;
        }

        &.create-advert {
          margin-top: 5vh;
        }

        a {
          font-size: 1.125rem;
          color: $base-color-blue;
          font-weight: bold;

          @media (max-width: 1310px) {
            font-size: 1rem;
          }

          &.action-button {
            color: $black;
          }

          &:hover {
            color: $black;
          }
        }
      }
    }
  }

  .logo-container {
    width: 50px;
    height: 54px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .auth-container {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    font-size: 1.125rem;

    @media (max-width: $breakpoint-1200) {
      font-size: 1rem;
    }

    .inner-link {
      @media (max-width: $breakpoint-960) {
        display: none;
      }
    }

    .divider {
      @media (max-width: $breakpoint-960) {
        font-size: 0rem;
      }
    }

    .mobile-menu-button-container {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .auth-button-wrapper {
      display: inline-block;

      .auth-button {
        margin: 0 0.3rem;
        font-size: 1.125rem;
        text-decoration: underline;

        @media (max-width: $breakpoint-960) {
          display: none;
        }

        @media (max-width: $breakpoint-1200) {
          font-size: 1rem;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .input-container {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        .auth-input {
          margin: 0;
        }

        label {
          font-size: 0.8rem;
          font-weight: 300;
          color: $short-description-color;
          padding-left: 0.2rem;
        }
      }

      .modal {
        position: fixed;
        z-index: 100;
        padding-bottom: 5vh;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.5);

        &.login {
          padding-top: 20vh;

          @media (max-width: $breakpoint-640) {
            padding-top: 10vh;
          }
        }

        &.registration {
          padding-top: 4vh;

          @media (max-width: $breakpoint-640) {
            padding-top: 0vh;
          }
        }
      }

      .pop-up {
        --width: 26.25rem;
        display: block;
        margin: auto;
        text-align: center;
        z-index: 10;
        width: var(--width);
        height: max-content;
        background-color: #ffffff;
        opacity: 1;
        box-shadow: 0px 0px 20px rgba($black, 0.25);

        &.reset {
          padding: 1rem 0rem 0.5rem;

          .content-wrapper {
            padding: 0 4rem;

            .auth-input {
              margin-bottom: 0;
            }

            .action-button:first-child {
              margin: 1.5rem 0 1rem;
            }
          }
        }

        @media (max-width: $breakpoint-640) {
          --width: 20rem;
        }

        .switch-container {
          position: relative;
          display: flex;
          width: 100%;
          padding-top: 2rem;
          margin: 3rem 0 2rem;

          @media (max-width: $breakpoint-640) {
            margin-top: 2vh;
          }

          .close-button {
            cursor: pointer;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10%;
            right: 10px;

            img {
              width: 20px;
              height: 20px;
            }
          }

          .switch-button {
            width: 50%;
            padding: 0.7rem;
            text-align: center;
            font-weight: bold;
            font-size: 1.125rem;
            color: $short-description-color;
            border-bottom: 1px solid $products-border-color;

            &:hover {
              color: $black;
              border-bottom: 1px solid rgba($black, 0.7);
            }
          }
        }

        .content-wrapper {
          padding: 0 5rem;

          @media (max-width: $breakpoint-640) {
            padding: 0 5vw;
          }

          p {
            font-size: 0.75rem;
          }

          .forms-container {
            #last-child {
              margin-bottom: 0;
            }
          }

          .buttons-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            justify-content: space-between;

            .reset-password-button {
              width: max-content;
              font-size: 0.75rem;
              font-weight: normal;
              color: $base-color-blue;
            }
          }
        }
      }
    }
  }

  .inner-link {
    margin-right: 2rem;
    font-size: 1.125rem;
    color: $base-color-blue;
    font-weight: bold;

    @media (max-width: 1310px) {
      font-size: 1rem;
    }

    &:hover {
      color: $black;
    }
  }

  .auth-user {
    width: 40%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    align-items: center;

    .inner-link {
      @media (max-width: $breakpoint-960) {
        display: none;
      }
    }

    .user-container {
      display: flex;
      justify-content: flex-end;
    }

    .name-container {
      display: flex;
      flex-direction: column;
      align-content: flex-end;
      text-align: right;

      @media (max-width: $breakpoint-960) {
        display: none;
      }
    }

    .img-container {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      cursor: pointer;

      .avatar {
        width: 40px;
        height: 40px;
        border: 1px solid $base-color-blue;
        border-radius: 50%;
      }

      .arrow {
        width: 30px;
        height: 30px;
      }
    }

    .pop-up {
      position: absolute;
      visibility: hidden;
      width: max-content;
      z-index: 10;
      top: 0%;
      transition: top 0.2s;

      &.is-open {
        top: 100%;
        visibility: visible;
      }

      .nav {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: max-content;
        padding: 0.3rem;
        margin-right: 1vw;
        border-radius: 15px;
        box-shadow: 0px 0px 10px rgba(33, 37, 41, 0.25);

        .nav-item {
          font-size: 1.125rem;
          display: flex;
          width: 12.5rem;
          align-items: center;
          padding: 0.5rem;

          img {
            width: 21px;
            height: 21px;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
