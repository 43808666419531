.company-detail-wrapper {
  display: flex;
  margin-bottom: 5vh;
  background-color: #fff;

  @media (max-width: $breakpoint-1200) {
    padding: 0 5vw 0 1vw;
  }

  @media (max-width: $breakpoint-960) {
    flex-direction: column;
  }

  .company-detail-left-column {
    width: 15rem;
    max-width: 15rem;
    min-width: 15rem;
    padding: 2rem 1.5vw;
    border-right: 1px solid $products-border-color;

    @media (max-width: $breakpoint-960) {
      width: unset;
      max-width: unset;
      min-width: unset;
      border: none;
      font-size: 1.25rem;
    }

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15rem;
      max-height: 15rem;
      margin-bottom: 1.5rem;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .contacts {
      &.site {
        color: $base-color-blue;
      }
      padding: 1.25rem 0;

      a {
        display: block;
        color: $base-color-blue;
        padding: 0.2rem 0;
      }
    }

    .work-days p{
      .holiday {
        color: $invalid-form-border-color;
      }

      .work-day-name {
        display: inline-block;
        width: 8rem;
        min-width: 8rem;

        @media (max-width: $breakpoint-960) {
          width: 10rem;
          min-width: 10rem;
        }

        &.holiday {
          color: $invalid-form-border-color;
        }
      }
    }

    .credentials {
      padding-top: 1.5rem;

      p {
        color: $short-description-color;
      }
    }
  }

  .company-detail-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4vh 2vw 2vh;
    background-color: #fff;

    .company-detail-header {
      h2 {
        padding: 0 0 1rem;
      }
    }

    .company-dashboard-wrapper__nav {
      margin-top: 5vh;
    }
  }
}
