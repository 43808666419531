.reset-pass-wrapper {
  position: relative;
  top: 15vh;
  width: 25rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  background-color: #fff;

  h2 {
    margin-bottom: 1rem;
  }
}

.confirm-email-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 1px;

  &.confirmed {
    top: 15vh;
    width: 25rem;
    padding: 1rem 2rem;
    background-color: #fff;
  }
}
