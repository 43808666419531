.create-product-container, .company-registration-container {
  margin-bottom: 4vh;
  width: 100%;
  background-color: #fff;

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1.5rem 0 1rem 3rem;
    color: $black;
  }

  .payload-container {
    margin: 0 5vw;
    padding: 2vh 0;
    background-color: #ffffff;
    border-bottom: 1px solid $form-border-color;

    &.photos {
      border-bottom: 0px;
    }

    .product-input {
      width: 35%;
      min-width: 15rem;
      margin: 0;
    }

    .input-container {
      display: flex;
      align-items: center;
      margin: 1.5rem 0;

      @media (max-width: $breakpoint-960) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .product-input {
          width: 100%;
        }
      }

      &.exchange {
        align-items: baseline;
      }

      &.label-top {
        align-items: flex-start;
      }

      label {
        width: 15rem;
        min-width: 15rem;
        font-size: 1.125rem;
        font-weight: normal;

        @media (max-width: $breakpoint-960) {
          color: $short-description-color;
          font-size: 1rem;
          padding-left: 0.2rem;
        }
      }

      input[type='range'] {
        max-width: 480px;
        margin-right: 2rem;
      }

      &.textarea {
        align-items: flex-start;
        
        textarea {
          height: 100%;
          width: 100%;
          max-width: 30rem;

          @media (max-width: $breakpoint-960) {
            max-width: 100%;
          }
        }
      }

      &.condition {
        @media (max-width: $breakpoint-960) {
          display: none;
        }
      }

      &.mobile-condition {
        @media (min-width: calc(#{$breakpoint-960} + 1px)) {
          display: none;
        }
      }
    }

    .price-container, .address-container {
      display: flex;
      flex-direction: column;
      width: 35%;

      @media (max-width: $breakpoint-960) {
        width: 100%;
      }
      
      .price-form, .address-form {
        display: flex;
        width: 100%;

        .left {
          width: 70%;
          min-width: 0;
        }

        .right {
          width: 30%;
          min-width: 5rem;
        }
      }

      .address-form {
        .left {
          width: 60%;
        }

        .right {
          width: 35%;
          margin-left: 5%;
        }
      }

      .bottom {
        width: 100%;
        margin-top: 1.5rem;
      }
    }

    .address-container {
      width: 50%;

      @media (max-width: $breakpoint-960) {
        width: 100%;        
      };
    }

    .exchange-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .product-input {
        &.bottom {
          margin-top: 1rem;
          width: 100%;
          max-width: 480px;
        }
      }
    }

    .location-container {
      display: flex;
      width: 100%;

      .location-item {
        margin-right: 1rem;
        min-width: 0;
      }
    }
  }


  .images-container {
    display: flex;
    position: relative;
    flex-wrap: wrap;

    @media(max-width: 490px) {
      justify-content: center;
    }

    .item-option {
      display: none;
      position: absolute;
      width: 8rem;
      height: 1rem;
      padding: 0.3rem 1rem 0.5rem;
      color: #fff;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      &.delete {
        background-color: $invalid-form-border-color;
        top: calc(100% - 3rem);
      }

      &.main {
        background-color: $black;
        top: 1rem;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10rem;
      height: 8rem;
      max-width: 10rem;
      max-height: 8rem;
      border: 0.2rem solid $base-color-blue;
      margin: 0.5vh 1vw;

      &:hover {
        .item-option {
          display: block;
        }
      }

      img {
        width: auto;
        height: 8rem;
        max-width: 10rem;
        max-height: 8rem;
      }
    }
  }

  .submit-container {
    width: max-content;
    min-width: 16rem;
    margin: 0 auto;
    padding: 4vh 0;

    @media (max-width: 400px) {
      width: 70%;
      padding: 4vh 15%;
    }
  }

  .images-input-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    padding: 2rem;
    border: 3px dashed $form-border-color;

    .action-button {
      width: 20%;
      min-width: 9.375rem;
    }

    .image-wrapper {
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.company-registration-container {
  h2 {
    padding: 2rem 0 !important;
  }

  .company-name-description {
    font-size: 0.75rem;
    color: $short-description-color;
    margin-left: 1rem;
    width: 40%;

    @media (max-width: $breakpoint-960) {
      display: none;
    }
  }

  .logo-label {
    @media (max-width: $breakpoint-960) {
      display: none;
    }
  }

  .logo-section {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .logo-container {
      width: 100px;
      height: 100px;
      min-width: 100px;
      margin-right: 1rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .action-container {
      align-items: center;
      padding: 0;

      &.logo-mobile {
        @media (max-width: $breakpoint-960) {
          flex-direction: column;
          align-items: flex-start;
          max-width: 200px;
        }
      }

      p {
        font-size: 0.75rem;
        color: $short-description-color;
      }

      .action-button {
        margin: 0 1rem 0 0;

        @media (max-width: $breakpoint-960) {
          margin-bottom: 1rem;
        }

        p {
          font-size: 0.9rem;
          color: #fff;
        }
      }
    }
  }

  .work-days-container {
    display: flex;

    &.column {
      flex-direction: column;
    }
  }

  .work-time-container {
    display: flex;
    align-items: center;

    .input-container {
      margin: 0.5rem 0;
    }

    p {
      margin: 0 1rem;
    }

    .product-input {
      max-width: 100px;
      min-width: 80px;
    }

    &.column {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 4vh;
    }
  }
}
