@import '../_variables';
@import './profile';
@import '../forms.scss';

.dashboard-wrapper {
  padding: 0 10vw;
  background-color: $main-background-color;

  @media (max-width: $breakpoint-1200) {
    padding: 0 2vw;
  }

  @media (max-width: $breakpoint-960) {
    padding: 0;
    background-color: #fff;
  }

  h2 {
    padding-left: 4vw;
    padding-bottom: 0.5vh;
  }

  .dashboard-container {
    display: flex;
    background-color: inherit;

    .dashboard-items {
      width: 100%;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 4vh 2vw;
      border-right: 1px solid $products-border-color;

      @media (max-width: $breakpoint-960) {
        display: none;
      }

      .nav-item {
        font-size: 1.125rem;
        display: flex;
        width: 12.5rem;
        align-items: center;
        padding: 0.75rem 0.5rem;

        &.exit {
          margin: 1rem 0 3rem;
        }
  
        img {
          width: 21px;
          height: 21px;
          margin-right: 0.5rem;
        }
      }
    }
  }
  
  .nav {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: max-content;
    padding: 0.3rem;
    margin-right: 1vw;

    .nav-item {
      font-size: 1.125rem;
      display: flex;
      width: 12.5rem;
      align-items: center;
      padding: 0.5rem;

      img {
        width: 21px;
        height: 21px;
        margin-right: 0.5rem;
      }
    }
  }

  .person-products-container {
    width: 100%;
  }
}

.change-user-data-container {
  padding: 3vh 5vw; 
  background-color: #fff;
  min-height: 40vh;

  h3 {
    margin-bottom: 2rem;
  }

  p {
    font-size: 0.75rem;
    margin-bottom: 2rem;
  }

  .data-block {
    display: flex;
    align-items: center;
    width: 100%;

    &.flex-end {
      align-items: flex-end;
    }

    &.baseline {
      align-items: baseline;
    }

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      color: $base-color-blue;
    }

    label {
      width: 20%;
      min-width: 250px;
      margin: 0.75rem 0;
      color: $short-description-color;

      @media (max-width: 450px) {
        width: max-content;
        min-width: 0;
        margin: 0 0.2rem;
      }
    }

    input {
      max-width: 320px;

      @media (max-width: 450px) {
        max-width: unset;
      }

      &::after {
        content: 'hello';
      }
    }

    button {
      margin: 0 0 0 2rem;
      max-width: 16rem;

      @media (max-width: 450px) {
        margin: 1.5rem 0 0 0;
        width: 16rem;
      }
    }
  }

  .button-container {
    width: 30%;
    margin: 0 auto;

    @media (max-width: 450px) {
      width: 100%;
      margin: 0;
    }
  }
}

.dashboard__no-posts {
  display: flex;
  min-height: 20rem;
  padding: 6vh 5vw;

  @media (max-width: $breakpoint-640) {
    padding: 10vh 5vw;

    .image-container {
      display: none;
    }
  }

  .image-container {
    width: 100px;
    height: 100px;
    margin-right: 4vw;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .action-container {
    height: 100px;
    padding: 0.5rem 0;

    .action-button {
      margin-top: 1.5rem;
    }
  }
}
