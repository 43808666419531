@import "../_variables";

.main-wrapper {
  display: flex;
  position: relative;
  padding: 0 10vw;
  margin-bottom: 5vh;
  background-color: $main-background-color;

  @media (max-width: $breakpoint-1200) {
    padding: 0 2vw;
  }

  @media (max-width: $breakpoint-960) {
    padding: 0;
    background-color: #fff;
  }

  h2 {
    padding: 5vh 2vw 1vh;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.products-wrapper {
  display: flex;
  width: 100%;

  &.column {
    flex-direction: column;
  }
}

.product-list {
  width: 100%;
  background-color: #fff;

  .sort-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: #fff;
    border-bottom: 1px solid $products-border-color;

    &.without-currency {
      justify-content: flex-end;

      .sort-collection {
        width: max-content;
      }
    }

    &.company {
      display: none;
      height: 28px;

      @media (max-width: $breakpoint-960) {
        display: flex;
      }
    }

    @media (max-width: $breakpoint-960) {
      justify-content: flex-end;
    }

    @media (max-width: $breakpoint-640) {
      padding: 1vh 0.5rem 3vh;
      flex-direction: column;
      align-items: flex-end;
    }

    .filter-item {
      margin: 0;
      padding: 0.1rem;
      height: 1.875rem;
      font-size: 0.7rem;
      font-weight: bold;

      &.price-selector {
        max-width: 5rem;

        @media (max-width: $breakpoint-960) {
          margin-right: 0.2rem;
        }

        @media (max-width: $breakpoint-640) {
          margin-bottom: 1rem;
        }
      }
    }

    .mobile-sort-item {
      display: none;

      @media (max-width: $breakpoint-960) {
        display: block;
      }
    }

    .sort-collection {
      display: flex;
      width: 80%;
      max-width: 550px;
      justify-content: space-between;

      @media (max-width: $breakpoint-960) {
        display: none;
      }

      p {
        text-align: center;
        font-size: 0.75rem;
        padding: 0.1rem;
        font-weight: bold;
      }

      .sort-button {
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 0.1rem 0.2rem;
        margin: 0 1rem;
        text-align: center;

        p {
          color: $base-color-blue;
          font-weight: normal;
          font-size: 0.75rem;
        }

        img {
          width: 10px;
          height: 10px;
        }

        &.active {
          background-color: $main-background-color;
        }

        &.asc {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .product {
    display: flex;
    padding: 2rem 0;
    margin: 0 2rem;
    background-color: #fff;
    border-bottom: 1px solid $products-border-color;

    @media (max-width: $breakpoint-960) {
      margin: 0 1rem;
    }

    .product-image {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 250px;
      min-height: 150px;
      max-height: 150px;
      max-width: 250px;
      width: 250px;
      height: 150px;

      @media (max-width: 500px) {
        min-width: 100px;
        min-height: 75px;
        max-height: 75px;
        max-width: 100px;
        width: 100px;
        height: 75px;
      }

      img {
        width: auto;
        height: auto;
        max-height: inherit;
        max-width: inherit;
      }
    }

    .product-text-content {
      margin-left: 1.25rem;
      width: 100%;
      position: relative;
      font-size: 0.75rem;

      .controls-wrap {
        right:0;
        position: absolute;
        z-index:1;

        .controls {
          position:relative;

          .blue {
            color: $base-color-blue;
          }
          .red {
            color: $base-color-red;
          }

          a {
            padding-bottom: 1rem;
            float: right;
          }
  
          a:hover {
            text-decoration: underline;
          }
        }
      }

      h3 {
        font-size: 1.125rem;
        font-weight: bold;
        color: $base-color-blue;
      }

      h4 {
        margin: 0.5rem 0;

        font-weight: 400;
      }

      .description-container {
        display: flex;
        justify-content: space-between;
      }

      .short-description-wrapper {
        display: flex;
        justify-content: space-between;
      }

      .short-description {
        margin: 1rem 0;
        font-weight: normal;
        max-height: 3.55rem;
        overflow: hidden;
        color: $short-description-color;

        @media (max-width: $breakpoint-960) {
          &.vacancy {
            display: unset;
          }
        }
      }

      .company-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 80px;
        border: 1px solid $products-border-color;

        &.vacancy {
          margin-left: 10px;
        }

        &.product-item {
          height: 60px;
          width: 150px;
          img {
            height: 60px;
            max-height: 60px;
            width: auto;
          }
        }

        @media (max-width: $breakpoint-640) {
          display: none;
        }

        img {
          height: 80px;
          max-height: 80px;
          width: auto;
        }
      }

      .company-contacts {
        font-weight: bold;
      }

      .date-published {
        color: $short-description-color;
        font-weight: normal;
        &.footer {
          display: none;
          @media (max-width: $breakpoint-640) {
            display: inline;
          }
        }
      }

      .salary {
        font-size: 1.2rem;
        padding: 0.3rem 0;
        font-weight: bold;
      }

      .location {
        display: flex;

        p {
          font-size: 0.8rem;
          font-weight: normal;
        }

        .company::after {
          content: "|";
          padding: 0.5rem;
        }
      }

      .product-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-weight: 600;
        position: relative;

        @media (max-width: $breakpoint-640) {
          flex-direction: column;
          justify-content: flex-start;
        }

        .date-published {
          color: $short-description-color;
          font-weight: normal;
          @media (max-width: $breakpoint-640) {
            display: none;
          }
        }

        .labels {
          display: flex;
          justify-content: space-between;

          @media (max-width: $breakpoint-640) {
            flex-direction: column;
            justify-content: flex-start;
            order: 2;
          }

          .exchange, .pending, .draft {
            display: block;
            margin: 0 0 0.3rem 0.5rem;
            height: max-content;
            padding: 3px 10px;
            font-weight: normal;
            max-width: max-content;
            color: #ffffff;
            background-color: $base-color-orange;
            border-radius: 15px;

            @media (max-width: $breakpoint-640) {
              margin: 0;
            }
          }
          
          .draft {
            background-color: $base-color-red;
          }
        }
      }

      .product-footer {
        position: sticky;
        width:100%;
        top: 100%;
        display: flex;
        justify-content: space-between;

        @media (max-width: $breakpoint-640) {
          flex-direction: column;
          justify-content: flex-start;
        }

        .state-container {
          display: flex;

          @media (max-width: $breakpoint-960) {
            flex-direction: column;
            font-size: 0.8rem;
          }

          @media (max-width: $breakpoint-640) {
            order: 2;
            flex-direction: row;
          }

          img {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 5px;

            @media (max-width: $breakpoint-960) {
              width: 1.75rem;
              height: 1.75rem;
            }
          }

          .location,
          .working-hours,
          .condition,
          .ur-state {
            display: flex;
            align-items: flex-end;
            margin-right: 2vw;

            @media (max-width: $breakpoint-960) {
              align-items: center;
              margin: 0.2rem 2vw 0.2rem 0;
            }
          }

          .ur-state {
            color: $short-description-color;
          }

          .working-hours {
            @media (max-width: $breakpoint-640) {
              display: none;
            }
          }

          .location {
            img {
              @media (max-width: $breakpoint-640) {
                display: none;
              }
            }
          }

          .condition {
            @media (max-width: $breakpoint-960) {
              margin: 0.2rem 2vw 0.2rem 0.15rem;
            }
          }

          .condition img {
            width: 7rem;
            height: 1.4rem;

            @media (max-width: $breakpoint-640) {
              width: 5rem;
              height: 1rem;
            }
          }
        }

        .price-container {
          position: relative;
          text-align: right;
          font-size: 1.125rem;
          font-weight: bold;

          @media (max-width: $breakpoint-960) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }

          @media (max-width: $breakpoint-640) {
            order: 1;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
          }

          .currency {
            font-weight: normal;

            @media (max-width: $breakpoint-640) {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: space-around;
    margin: 1rem;
    padding: 1rem;

    .breadcrumbs {
      display: flex;

      a {
        justify-content: center;
        height: 31px;
        width: 31px;
        border-radius: 8px;

        &#start::after {
          content: "...";
          margin-left: 0.5rem;
        }

        &#end {
          margin-left: 0.5rem;
        }

        &#end::before {
          content: "...";
          margin-right: 0.5rem;
        }
      }
    }

    a,
    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 6rem;
      font-weight: normal;
      font-size: 1.125rem;
      color: $black;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.product-text-main-conten {
  position: relative;

  .company-edit-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.edit {
      position: absolute;
      bottom: 40px;
      right: 0;
      .action-button {
        background-color: red;
        background-color: white;
        padding: 0.5vh 0;
        margin: 5px 0;
        min-width: 160px;
        width: 20%;
        font-weight: normal;
        font-size: 0.8rem;

        &.delete {
          color: $invalid-form-border-color;
        }
        &.link {
          color: $base-color-blue;
        }
      }
    }
  }
}
