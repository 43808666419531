.company-wrapper {
  width: 100%;
  min-height: 20rem;
  background-color: #fff;

  .action-container {
    display: flex;
    padding: 4vh 4vw;

    .column {
      flex-direction: row;
      padding: 0;

      .cancel-request {
        margin-top: 20px;
        color: #db4040;
      }
    }

    .img-container {
      width: 100px;
      height: 100px;
      min-width: 100px;
      margin-right: 2rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .action-button {
      max-width: 14.375rem;
    }
  }
}
.company-wrapper:before {
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  content: "";
}

.company-wrapper__join-company,
.company-wrapper__user-pending {
  padding: 6vh 4vw;
  min-height: 20rem;

  .action-container {
    padding: 0;
    margin-top: 4vh;
    align-items: center;
    justify-content: space-around;

    @media (max-width: $breakpoint-640) {
      flex-direction: column;
    }

    select,
    button {
      margin: 0;
    }

    select {
      max-width: 60%;

      @media (max-width: $breakpoint-640) {
        max-width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  .invalid-select {
    font-size: 1rem;
    color: $invalid-form-border-color;
    text-align: center;
    margin-top: 4vh;
  }

  .company-missing {
    font-size: 0.75rem;
    text-align: center;
    margin-top: 4vh;

    a {
      font-size: 0.75rem;
      color: $base-color-blue;
    }
  }
}
